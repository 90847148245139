<template>
  <div class="dialog-box">
    <el-dialog
      :modelValue="props.show"
      width="460px"
      destroy-on-close
      style="padding: 0"
      :close-on-click-modal="false"
      :show-close="false"
    >
      <img class="close" @click="handleClose" src="https://static.ppkao.com/www/images/icon-close.png"/>

      <div class="wrap">
        <img class="scan-bg" src="https://static.ppkao.com/www/images/scan-bg.png">
        <img class="scan-man" src="https://static.ppkao.com/www/images/scan-man.png">
        <div class="box">
          <div class="qrcode-box">
            <div class="qrcode-img" >
              <el-skeleton style="width: 220px" :loading="loading" animated >
                <template #template>
                  <el-skeleton-item variant="image" style="width: 220px; height: 220px" />
                </template>
                <template #default>
                  <div class="icon-box" v-if="showIcon">
                    <img class="icon" src="https://static.ppkao.com/www/images/wx-icon.png">
                  </div>
                </template>
              </el-skeleton>
              <img class="qrcode"
                   :src="qrcode"
                   :style="{opacity: loading ? '0': '1'}"
                   @load="loading = false">
              <div v-if="timeout" class="timeout-box" @click="refreshQrcode">
                <span>二维码已过期<br/>点击刷新</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script setup>
import {getWxScanCode, getWxScanResult} from "~/api";

const modalStore = useModalStore();
const store = useUserStore();

const props = defineProps({
  show: {
    type: Boolean,
    required: true,
    default: false
  },
  loginSuccess: {
    type: Function
  }
});
let timer; // 倒计时
const showIcon = ref(true); // 显示icon
const loading = ref(true); // 购买二维码图片是否加载中
const qrcode = ref(''); // 登录二维码
const timeout = ref(false);// 是否轮询超时
const emit = defineEmits();
const handleClose = () => {
  console.log('[点击关闭]')
  clearInterval(timer);
  qrcode.value = '';
  loading.value = true;
  modalStore.setModal('scan', false);
};

// 监听 prop 的变化，并更新本地状态
watch(() => props.show, (newValue) => {
  console.log('[打开的值]:', newValue)
  if (typeof newValue === "string") {
    showIcon.value = false;
    qrcode.value = newValue;
  } else if (newValue) {
    refreshQrcode();
  }
});
const refreshQrcode = () => {
  getWxScanCode().then(res => {
    let count = 0;
    qrcode.value = res.QRCode;
    timeout.value = false;
    timer = setInterval(() => {
      if (count >= 90) { // 查询三分钟
        clearInterval(timer);// 查询了两分钟后没有结果就关闭查询
        timeout.value = true;
        return;
      }
      queryResult(res.Code);
      count++;
    }, 1000);
  })
}
const queryResult = (code) => {
  getWxScanResult(code).then(res => {
    if (res.S === '1') {
      store.setUserInfo(res.UserList[0]);
      handleClose();
      emit('success');
    }
  })
}
</script>

<style scoped lang="scss">
:deep(.el-dialog__header) {
  display: none;
}
.timeout-box {
  cursor: pointer;
  @include flex-center;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 100;
  backdrop-filter: blur(4px);
  border-radius: 10px;
  -webkit-filter: blur(.6);
  filter: blur(.6);
  text-align: center;
  @include font(var(--primary-text-color), 16px, 600);
  background: hsla(0, 0%, 100%, .6);
  -webkit-backdrop-filter: blur(4px);
  span {
    line-height: 24px;
    @include font(#050505, 16px, 600);
  }
}
.close {
  position: absolute;
  right: -50px;
  width: 38px;
  cursor: pointer;
}
.wrap {
  background: linear-gradient(to bottom, #F05613  10%,  #FFC4AA 40%);
  position: relative;
  width: 100%;
  height: 630px;
  @include flex-x-center;
  .scan-bg {
    width: 100%;
    height: 630px;
  }
  .scan-man {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 10;
  }
}
.box {
  overflow: hidden;
  padding: 2px;
  width: 400px;
  height: 360px;
  position: absolute;
  border-radius: 40px;
  background-color: #fff4ef;
  top: 235px;
  @include flex-col-center;
  @include flex-center;
  .qrcode-box {
    transition: all .4s;
    background: var(--bg-color);
    border: 1px solid var(--border-2-color);
    box-shadow: 0 2px 6px 0 rgba(228, 215, 215, 0);
    position: relative;
    margin-top: 30px;
    z-index: 99;
    &::before {
      content: '';
      display: block;
      position: absolute;
      width: 280px;
      height: 86px;
      transform: rotate(180deg);
      top: -30px;
      left: -30px;
      background: url('https://static.ppkao.com/www/images/scan-line.png') no-repeat;
      background-size: 280px 86px;
    }
    &::after {
      content: '';
      display: block;
      position: absolute;
      width: 280px;
      height: 86px;
      bottom: -30px;
      left: -30px;
      background: url('https://static.ppkao.com/www/images/scan-line.png') no-repeat;
      background-size: 280px 86px;
    }
    .qrcode-img {
      width: 220px;
      height: 220px;
      transition: all .4s;
      position: relative;
      @include flex-center;
      .qrcode {
        width: 100%;
        height: 100%;
        position: absolute;
      }
      .icon-box{
        background-color: #FFFFFF;
        position: absolute;
        z-index: 100;
        .icon {
          width: 32px;
          transition: all .4s;
        }
      }
    }
  }
}
</style>
